import axios from "axios";
axios.defaults.withCredentials = true;

const baseURL = "/api/user/settings/language";
const tokenURL = "/api/auth/token";

/**
 * Service function responsible for updating the user's language.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} data data object containing the new first/last name and new email.
 * @returns response from backend
 */
export const sendUserSettingsLanguage = async (data) => {
  try {
    const response = await axios.post(baseURL, data, { withCredentials: true });
    return response.data;
  } catch (e) {
    await axios.post(tokenURL, { withCredentials: true });
    const response = await axios.post(baseURL, data, { withCredentials: true });
    return response.data;
  }
};
