import { DropdownButton } from "react-bootstrap";
import DropdownItem from "react-bootstrap/esm/DropdownItem";
import { toast } from "react-toastify";
import { default as i18nAliased, Language, LanguageNames, storageKey } from "src/i18n";
import { sendUserSettingsLanguage } from "src/services/userSettings/sendUserSettingsLanguage";
import { StyledLangDropdown } from "./LangDropdown.styled";

export const LangDropdown = ({
  i18n,
  autoStore,
  defaultLanguage,
  absolutePosition = true,
}: {
  i18n: typeof i18nAliased;
  autoStore: boolean;
  defaultLanguage?: Language;
  absolutePosition?: boolean;
}) => {
  const currentLang = defaultLanguage || i18n.language;
  const displayLangs = i18n.languages.filter((l: string) => l !== currentLang) as Language[];

  const handleLangSwitch = async (lang: Language): Promise<void> => {
    await i18n.changeLanguage(lang);
    localStorage.setItem(storageKey, lang);

    if (autoStore) {
      try {
        await sendUserSettingsLanguage({ language: lang });
        toast.success(i18n.t<string>("components.userSettings.tabs.settings.languageUpdateSuccess")); // Define string type due to old TS / i18n
      } catch (e) {
        toast.error(i18n.t<string>("components.userSettings.tabs.settings.languageUpdateError"));
        // We could revert in the UI, but since the setting will work for the current browser
        // it's best not to. At some stage, however, the language will switch back to default.
      }
    }
  };

  return (
    <StyledLangDropdown className={!!absolutePosition ? "absolute-positioned" : ""}>
      <DropdownButton title={LanguageNames[currentLang]} drop="down" className="lang-menu" variant="default">
        {displayLangs.map((lang: Language, idx: number) => {
          return (
            <DropdownItem
              key={idx}
              as="button"
              onClick={async (event) => {
                event?.preventDefault(); // Otherwise the form will submit
                await handleLangSwitch(lang);
              }}
            >
              {LanguageNames[lang]}
            </DropdownItem>
          );
        })}
      </DropdownButton>
    </StyledLangDropdown>
  );
};
