import { useEffect, useState } from "react";
import { Tab, Row, Col, Container, Tabs } from "react-bootstrap";
import { StyledUserSettings } from "./UserSettings.styled";
import UserSettingsBasic from "./UserSettingsBasic";
import { SuccessfullBasicChange } from "./UserSettingsBasic";
import UserSettingsPassword from "./UserSettingsPassword";
import { SuccessfullPasswordChange } from "./UserSettingsPassword";
import UserSettingsTags from "./UserSettingsTags/UserSettingsTags";
import UserSettingsAlerts from "./UserSettingsAlerts";
import UserSettingsPaymentMethod from "./UserSettingsPaymentMethod/UserSettingsPaymentMethod";
import { sendUserSettingsBasic } from "../../../services/userSettings/sendUserSettingsBasic";
import { sendUserSettingsPassword } from "../../../services/userSettings/sendUserSettingsPassword";
import { logger } from "../../../utils/logger";
import User from "../../../model/Classes/User";
import { Nullable, StateHandler } from "../../../model/Utilities/Types";
import i18N from "../../../i18n";
import { useTranslation } from "react-i18next";

declare interface UserSettingsProps {
  user: User;
  setUser: StateHandler<Nullable<User>>;
  history: any;
}
/**
 * Component for the user settings. The component returns a tab menu from where the corresponding tasks can be comepleted.
 * The component also contains most states for the form fields (reasoning in the comments above the state) + helper functions.
 *
 * When the user changes their settings or password, the user needs to be logged out for the changes to take effect. Since it's
 * nicer for the user to see a page notifying them if the operation was successful (and log them out and redirecting to the
 * login page), we need to handle this. This is done by using the states successfullChange and successfullPasswordChange. E.g.
 * when the user changes their first name and the submission is successful, the state successfullChange will be true. This will
 * render a special component for the success page notifying the user that the change was successful. After 3 seconds, the user
 * will be logged out and redirected to the login page. Same applies to changing the password.
 * @param {User} user state for the user of the application
 * @param {*} setUser state handler for the user state
 * @param {*} history history object
 * @returns the settings page
 */
const UserSettings = ({ user, setUser, history }: UserSettingsProps) => {
  //The reason why most states are up here instead of at their respective components is due to the fact
  //that the states are needed for the functions handling the respective tasks (editing, moving, deleting).
  //It's easier to manage them if they are in this component
  const [newFirstName, setNewFirstName] = useState(user.first_name); //state for the new first name (defaulted to the users existing first name)
  const [newLastName, setNewLastName] = useState(user.last_name); //state for the new last name (defaulted to the users existing last name)
  const [oldPassword, setOldPassword] = useState(""); //state for the old password
  const [newPassword, setNewPassword] = useState(""); //state for the new password
  const [newConfirmPassword, setNewConfirmPassword] = useState(""); //state for the confirm password
  const [successfullChange, setSuccessfullChange] = useState(false); //state for toggling the SuccessfullBasicChange component
  const [successfullPasswordChange, setSuccessfullPasswordChange] = useState(false); //state for toggling the SuccessfullPasswordChange component
  const [successfullAddCard, setSuccessfullAddCard] = useState(false); //state for showing an alert notifying the user that adding a card was successful
  const [activeKey, setActiveKey] = useState("");
  const { t } = useTranslation("common", {
    i18n: i18N,
  });
  const [toAddTag, setToAddTag] = useState(false);
  const [cpId, setCpId] = useState("");
  const [cpLabel, setCpLabel] = useState("");

  /**
   * Asynchronous helper function for handling basic user settings submission. It tries to send the data to the backend,
   * and if successful, return true. If the backend does not succeed in the operation, return false.
   * @returns true if successful, false otherwise
   */
  const handleSubmitUserSettingsBasic = async () => {
    //Data object containing the new details of the user
    const data = {
      new_first_name: newFirstName,
      new_last_name: newLastName,
    };

    try {
      //Try to call the sendUserSettingsBasic() service function for changing the users basic settings.
      await sendUserSettingsBasic(data);
      return true; //Success! Return true
    } catch (e) {
      logger(e);

      return false; //Failed, return false
    }
  };

  /**
   * Asynchronous helper function for handling new password submission. It tries to send the data to the backend,
   * and if successful, returns [true]. If the backend does not succeed in the operation, return [false, error].
   * @returns [true] if successful, [false, error] otherwise
   */
  const handleSubmitUserSettingsPassword = async () => {
    //Data object containing the old and new password
    const data = {
      old_password: oldPassword,
      new_password: newPassword,
    };

    //Try to call the sendUserSettingsPassword() service function for changing the users password.
    const res = await sendUserSettingsPassword(data);
    return res;
  };

  useEffect(() => {
    /**
     * Helper function for determining which tab to be shown initially when the user accesses this page.
     * This function was moved into a useEffect hook since we want to keep tabs (hehe)
     * on which tab is selected
     */
    const checkDefaultActiveKey = () => {
      //Check if a state exists
      if (history.location.state) {
        //window.history.replaceState is used instead of pushing the history stack to this page due to
        //the fact that pushing the history stack to this page causes a rerender to occur. This would
        //cause the tags tab to be visible again initially when e.g. refreshing the page.
        //window.history.replaceState does not cause a rerender.
        const state = history.location.state;

        if (state.fromLandingPageShowCards) {
          window.history.replaceState({}, document.title);
          setActiveKey("paymentMethod");
        }
        if (state.cpId && state.cpLabel) {
          setToAddTag(true);
          setCpId(state.cpId);
          setCpLabel(state.cpLabel);
          window.history.replaceState({}, document.title);
          setActiveKey("tags");
        }
      } else {
        //No redirection
        setActiveKey("settings");
      }
    };
    checkDefaultActiveKey();
  }, [history.location.state]);

  if (successfullChange) return <SuccessfullBasicChange />; //If the successfullChange state is true, show the SuccessfullBasicChange component
  if (successfullPasswordChange) return <SuccessfullPasswordChange />; //If the successfullPasswordChange state is true, show the SuccessfullPasswordChange component

  const handleKeyChange = (eventValue: any) => {
    setActiveKey(eventValue);
  };

  /**
   * The component returns a header + tab menu for the different settings. The Tabs component is responsible
   * for making sure the correct component is displayed when the user switches tabs.
   */
  return (
    <StyledUserSettings className="top-level-component">
      <Container id="component-margin">
        {/*Row for the header*/}
        <Row className="mb-3" style={{ textAlign: "center" }}>
          <Col>
            <h2>{t("components.userSettings.tabs.settings.title")}</h2>
          </Col>
        </Row>
        {/*Row containing the different tabs*/}
        <Row>
          <Tabs activeKey={activeKey} id="" className="mb-3" onSelect={handleKeyChange}>
            <Tab eventKey="settings" title={t("components.userSettings.tabs.settings.title")}>
              <UserSettingsBasic
                user={user}
                newFirstName={newFirstName}
                newLastName={newLastName}
                setNewFirstName={setNewFirstName}
                setUser={setUser}
                setNewLastName={setNewLastName}
                handleSubmitUserSettingsBasic={handleSubmitUserSettingsBasic}
                setSuccessfullChange={setSuccessfullChange}
                history={history}
              />
            </Tab>
            <Tab eventKey="paymentMethod" title={t("components.userSettings.tabs.payment.title")}>
              <UserSettingsPaymentMethod
                successfullAddCard={successfullAddCard}
                setSuccessfullAddCard={setSuccessfullAddCard}
                activeKey={activeKey}
              />
            </Tab>
            <Tab eventKey="tags" title={t("components.userSettings.tabs.tags.title")}>
              <UserSettingsTags
                user={user}
                redirectToAddTag={toAddTag}
                cpId={cpId}
                setCpId={setCpId}
                cpLabel={cpLabel}
              />
            </Tab>
            <Tab eventKey="password" title={t("components.userSettings.tabs.password.title")}>
              <UserSettingsPassword
                oldPassword={oldPassword}
                newPassword={newPassword}
                newConfirmPassword={newConfirmPassword}
                setOldPassword={setOldPassword}
                setNewPassword={setNewPassword}
                setNewConfirmPassword={setNewConfirmPassword}
                setUser={setUser}
                setSuccessfullPasswordChange={setSuccessfullPasswordChange}
                handleSubmitUserSettingsPassword={handleSubmitUserSettingsPassword}
                history={history}
              />
            </Tab>
            {user && user.user_level > 0 && (
              <Tab eventKey="alerts" title={t("components.userSettings.tabs.alerts.title")}>
                <UserSettingsAlerts />
              </Tab>
            )}
          </Tabs>
        </Row>
      </Container>
    </StyledUserSettings>
  );
};

export default UserSettings;
