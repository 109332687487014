import { useState, useEffect, useRef } from "react";
import { register } from "../../../services/authentication/register";
import { StyledRegisterForm } from "./Register.styled";
import { LangDropdown } from "../LangDropdown/LangDropdown";
import { Link } from "react-router-dom";
import logo from "../../../resources/WatteryLogoGreen.svg";
import { Form, Row, Col, Button, Alert, Container, Image } from "react-bootstrap";
import { toggleAlertsOff } from "../../../utils/toggleAlertsOff";
import ReCAPTCHA from "react-google-recaptcha";
import { reCAPTCHASiteKey } from "../../..";
import { logger } from "../../../utils/logger";
import { useTranslation } from "react-i18next";
import i18n from "../../../i18n";
import { parseInviteToken } from "../../../services/authentication/parseInviteToken";

const RegisterForm = ({
  firstName,
  lastName,
  language,
  email,
  password,
  confirmPassword,
  setFirstName,
  setLastName,
  setEmail,
  setPassword,
  setConfirmPassword,
  handleSubmit,
  errors,
  setErrors,
  tosClicked,
  setTosClicked,
  disabledSubmit,
}) => {
  const [firstNameAlert, setFirstNameAlert] = useState(false); //state for showing an alert notifying the user of an invalid first name
  const [lastNameAlert, setLastNameAlert] = useState(false); //state for showing an alert notifying the user of an invalid last name
  const [invalidEmailAlert, setInvalidEmailAlert] = useState(false); //state for showing an alert notifying the user of an invalid email
  const [passwordSimilarAlert, setPasswordSimilarAlert] = useState(false); //state for showing an alert notifying the user that the password and confirm password fields are not the same
  const [passwordTooShortAlert, setPasswordTooShortAlert] = useState(false); //state for showing an alert notifying the user that the password is too short
  const [tosNotClickedAlert, setTosNotClickedAlert] = useState(false); //state for showing an alert notifying the user to agree to the Terms of Service
  const [recaptchaNotClicked, setRecaptchaNotClicked] = useState(false); //state for showing an alert notifying the user to pass the reCaptcha
  const { t } = useTranslation("common", { i18n: i18n });
  const recaptchaRef = useRef<any>();

  /**
   * Helper function for validating the form fields. The function has a variable success which is set to true.
   * Then it checks all the validation criteria:
   * - No empty fields
   * - Valid email
   * - Password must be at least 8 characters long
   * - Password and confirm password fields must match
   * - Checkbox for agreeing with the ToS is clicked
   * - Checkbox for reCAPTCHA is clicked
   *
   * If any of these criteria fail, toggle the corresponding alert on and set the success variable to false
   *
   * @param {*} captchaToken value for the captcha token
   * @return true if no criteria was violated, otherwise false
   */
  const validate = (captchaToken) => {
    let success = true; //Initialize success to true in the beginning

    if (firstName === "") {
      success = false;
      setFirstNameAlert(true);
    } //First name field cannot be empty
    if (lastName === "") {
      success = false;
      setLastNameAlert(true);
    } //Last name field cannot be empty

    //Regular expression for a valid email, straight up copied from stack overflow
    let re =
      //eslint-disable-next-line no-useless-escape
      /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    if (!re.test(email)) {
      success = false;
      setInvalidEmailAlert(true);
    } //Email must be valid based on the regular expression
    if (password.length < 8) {
      success = false;
      setPasswordTooShortAlert(true);
    } //Password must be at least 8 characters long

    //The password and confirm password fields must match. Additionally, neither can be empty
    if (password !== confirmPassword || (password === "" && confirmPassword === "")) {
      success = false;
      setPasswordSimilarAlert(true);
    }
    //ToS checkbox must be clicked
    if (!tosClicked) {
      success = false;
      setTosNotClickedAlert(true);
    }

    if (captchaToken === "") {
      success = false;
      setRecaptchaNotClicked(true);
    }
    //Return true if validation passed, false otherwise
    return success;
  };

  /**
   * Helper function for possibly calling the handleSubmit() function for submitting the form. The function
   * initially toggles all the alerts off, and then calls the validate() function. If the validation failed,
   * exit the function. Otherwise, create a data object that is passed to the handleSubmit() function.
   */
  const sendData = async () => {
    //Call the utility function toggleAlertsOff to toggle all the alerts off
    toggleAlertsOff([
      setFirstNameAlert,
      setLastNameAlert,
      setInvalidEmailAlert,
      setPasswordSimilarAlert,
      setPasswordTooShortAlert,
      setTosNotClickedAlert,
      setRecaptchaNotClicked,
      setErrors,
    ]);

    const captchaToken = await recaptchaRef.current.getValue();

    if (!validate(captchaToken)) return; //If the validation failed, exit the function

    //Validation passed, create the needed data object
    const data = {
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      verification: confirmPassword,
      reCaptchaToken: captchaToken,
    };

    //Call the helper function handleSubmit() with the data object to handle submission functionality
    handleSubmit(data);
  };

  /**
   * Return the following:
   * - Wattery logo
   * - Possible error alert if an error occured on the backend when submitting the registration form
   * - The registration form (fields has a possible alert below it if the input was invalid)
   * - Submit button
   * - Link to the login page
   */
  return (
    <Container id="component-margin">
      {/*Row for the Wattery logo*/}
      <Row>
        <Image className="logo" src={logo} alt="Wattery logo" />
      </Row>
      {/*Row for an error alert notifying the user that an error occured on the backend when submitting the registration form*/}

      <Row /* xs="auto" sm="auto" */>
        {errors && (
          <Alert key="error" variant="danger">
            {t("global.alert.failure.register")}
          </Alert>
        )}
      </Row>

      {/*Row for the registration form*/}
      <Row /* xs="auto" sm="auto" */>
        {/*Form for where the different inputs are connected to*/}
        <Form className="registerForm">
          <Row className="mb-3">
            <Form.Group>
              <Form.Label>{t("components.register.language")}</Form.Label>
              <LangDropdown i18n={i18n} autoStore={false} absolutePosition={false} defaultLanguage={language} />
            </Form.Group>
          </Row>
          <Row className="mb-3">
            {/*Form.Group for the first name input, contains the form label and input*/}
            <Form.Group as={Col} controlId="formGridFirstName" onChange={(event) => setFirstName(event.target.value)}>
              <Form.Label>{t("components.userSettings.tabs.settings.first")}</Form.Label>
              <Form.Control type="text" />
            </Form.Group>

            {/*Form.Group for the last name input, contains the form label and input*/}
            <Form.Group as={Col} controlId="formGridLastName" onChange={(event) => setLastName(event.target.value)}>
              <Form.Label>{t("components.userSettings.tabs.settings.last")}</Form.Label>
              <Form.Control type="text" />
            </Form.Group>
          </Row>

          {firstNameAlert && ( //Alert notifying the user if the first name input is invalid
            <Alert key="firstNameAlert" variant="danger">
              {t("global.alert.failure.registerFirst")}
            </Alert>
          )}
          {lastNameAlert && ( //Alert notifying the user if the last name input is invalid
            <Alert key="lastNameAlert" variant="danger">
              {t("global.alert.failure.registerLast")}
            </Alert>
          )}

          {/*Form.Group for the email input, contains the form label, input, and a text under the input*/}
          <Form.Group className="mb-3" controlId="formGridEmail">
            <Form.Label>{t("components.loginPage.static.email")}</Form.Label>
            <Form.Control type="email" value={email} onChange={(event) => setEmail(event.target.value)} />
            <Form.Text className="text-muted">{t("components.register.reassure")}</Form.Text>
          </Form.Group>

          {invalidEmailAlert && ( //Alert notifying the user if the email is invalid
            <Alert key="invalidEmailAlert" variant="danger">
              {t("global.alert.failure.registerEmail")}
            </Alert>
          )}

          {/*Form.Group for the password input, contain the form label, input, and text under the input*/}
          <Form.Group
            className="mb-3"
            controlId="formGridPassword"
            onChange={(event) => setPassword(event.target.value)}
          >
            <Form.Label>{t("components.loginPage.static.password")}</Form.Label>
            <Form.Control type="password" />
            <Form.Text className="text-muted">{t("components.register.pwLength")}</Form.Text>
          </Form.Group>

          {passwordTooShortAlert && ( //Alert notifying the user if the password is too short
            <Alert key="passwordTooShortAlert" variant="danger">
              {t("global.alert.failure.pwShort")}
            </Alert>
          )}

          {/*Form.Group for the confirm password input, contains the form label and input*/}
          <Form.Group
            className="mb-3"
            controlId="formGridConfirmPassword"
            onChange={(event) => setConfirmPassword(event.target.value)}
          >
            <Form.Label>{t("components.register.confirmPw")}</Form.Label>
            <Form.Control type="password" />
          </Form.Group>

          {passwordSimilarAlert && ( //Alert notifying the user if the password and confirm password inputs don't match
            <Alert key="passwordSimilarAlert" variant="danger">
              {t("global.alert.failure.noPwMatch")}
            </Alert>
          )}

          {/*Form.Group for the ToS agree checkbox*/}
          <Form.Group controlId="formGridTos" onChange={() => setTosClicked(!tosClicked)}>
            <Form.Check
              type="checkbox"
              label={
                <p>
                  {t("components.register.tos.acknowledge")}{" "}
                  <a href="/terms-of-service" target="_blank" rel="noreferrer">
                    {t("components.register.tos.tos")}
                  </a>
                  .
                </p>
              }
            />
          </Form.Group>

          {tosNotClickedAlert && (
            <Alert key="tosNotClickedAlert" variant="danger">
              {t("global.alert.failure.registerTos")}
            </Alert>
          )}

          <ReCAPTCHA className="mb-3" ref={recaptchaRef} hl={i18n.language} sitekey={reCAPTCHASiteKey} />

          {recaptchaNotClicked && (
            <Alert key="recaptchaNotClicked" variant="danger">
              {t("global.alert.failure.reCaptchaPass")}
            </Alert>
          )}

          {/*Form.Group for the submit button, calls the sendData() function when pressed*/}
          <Form.Group as={Row}>
            <Col>
              <Button
                className="mb-3"
                variant="primary"
                onClick={sendData}
                id="submit-button"
                disabled={disabledSubmit}
              >
                {t("global.buttons.submit")}
              </Button>
            </Col>
          </Form.Group>

          {/*Form.Group for the link to the login page*/}
          <Form.Group as={Row} className="mb-5">
            <Col>
              <Link to="/login">{t("components.register.already")}</Link>
            </Col>
          </Form.Group>
        </Form>
      </Row>
    </Container>
  );
};

/**
 * Component containing functionality for the Register page. The component contains a function handleSubmit()
 * that handles the submission of the registration form. The function submits the form data to the backend, and
 * if successful, push the user to the login page and toggle an alert on to notify the user that registration was
 * successful. Otherwise, log the error and display an error alert notifying the user that the registration failed.
 * @param {*} setRegistrationSuccessAlert state handler for displaying a success alert to the user if registration was successful
 * @returns registration view
 */
const Register = ({ setRegistrationSuccessAlert, history, user }) => {
  const [firstName, setFirstName] = useState(""); //state for the first name field
  const [lastName, setLastName] = useState(""); //state for the last name field
  const [language, setLanguage] = useState<string | undefined>("");
  const [email, setEmail] = useState(""); //state for the email field
  const [password, setPassword] = useState(""); //state for the password field
  const [confirmPassword, setConfirmPassword] = useState(""); //state for the confirm password field
  const [tosClicked, setTosClicked] = useState(false); //state for knowing if the user has agreed to the ToS
  const [errors, setErrors] = useState(false); //state for showing an error alert
  const [disabledSubmit, setDisabledSubmit] = useState(false); //state for disabling register button

  //useEffect hook that simply pushes users to the landing page if they try to access this page when
  //they are logged in
  useEffect(() => {
    if (user) history.push("/");
  }, [history, user]);

  useEffect(() => {
    async function getData(token: string) {
      const tokenParseRequest = await parseInviteToken(token);
      if (tokenParseRequest.success) {
        setEmail(tokenParseRequest.data.email);
        setLanguage(tokenParseRequest.data?.language);
      } else {
        history.push({ pathname: "/register" });
      }
    }

    const params = new URLSearchParams(window.location.search);
    const inviteToken = params.get("invite");
    if (inviteToken) {
      getData(inviteToken);
    }
    //eslint-disable-next-line
  }, []);

  /**
   * Asynchronous helper function for submitting the registration form. If successful, reset the form
   * fields and push the user to the login page. Additionally, set the registrationSuccessAlert to true
   * to notify the user that the registration was successful. If registration failed, log the error and
   * set the errors state to true notifying the user that the registration failed.
   * @param {*} data data object containing the needed information for the backend to register a new user
   */
  const handleSubmit = async (data) => {
    try {
      setDisabledSubmit(true);
      //Call the service function register() to send the registration data to the backend
      await register(data);

      //Registration was successful, reset the form fields
      setFirstName("");
      setLastName("");
      setEmail("");
      setPassword("");
      setConfirmPassword("");
      setDisabledSubmit(false);

      //Push the user to the login page and toggle an alert notifying the user that the registration was successful
      history.push("/login");
      setRegistrationSuccessAlert(true);
    } catch (e) {
      logger(e);

      setErrors(true); //Set the error alert on to notify the user that registration failed
      setDisabledSubmit(false);
    }
  };

  //Return the RegistrationForm component
  return (
    <StyledRegisterForm id="register-styled" className="top-level-component">
      <RegisterForm
        firstName={firstName}
        lastName={lastName}
        language={language}
        email={email}
        password={password}
        confirmPassword={confirmPassword}
        errors={errors}
        setErrors={setErrors}
        setFirstName={setFirstName}
        setLastName={setLastName}
        setEmail={setEmail}
        setPassword={setPassword}
        setConfirmPassword={setConfirmPassword}
        handleSubmit={handleSubmit}
        tosClicked={tosClicked}
        setTosClicked={setTosClicked}
        disabledSubmit={disabledSubmit}
      />
    </StyledRegisterForm>
  );
};

export default Register;
