import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import { en, sv, fi } from "src/resources/langs/locales";
import LanguageDetector from "i18next-browser-languagedetector";

export const storageKey: string = "currLang";

export enum Language {
  fi = "fi",
  en = "en",
  sv = "sv",
}

export enum LanguageNames {
  fi = "Suomi",
  en = "English",
  sv = "Svenska",
}

const options = {
  //should theoretically detect browser language based on these options
  order: ["localStorage", "navigator"],
  lookupLocalStorage: storageKey,
  caches: [storageKey], // Stops LanguageDetector from setting an additional, default key in storage
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: [Language.fi, Language.en, Language.sv],
    detection: options,
    debug: false,
    ns: "common",
    defaultNS: "common",
    resources: {
      en,
      fi,
      sv,
    },
    keySeparator: ".",
    supportedLngs: [Language.fi, Language.en, Language.sv],
    interpolation: {
      prefix: "{{",
      suffix: "}}",
      formatSeparator: ",",
    },
  });

export default i18n;
