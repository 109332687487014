import styled from "styled-components";

export const StyledLangDropdown = styled.div`
  &.absolute-positioned {
    position: absolute;
    top: 5%;
    right: 5rem;
  }

  &:not(.absolute-positioned) {
    button.dropdown-toggle {
      border: 0.5px solid #888;
    }
  }

  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 2rem;
  height: 2rem;
  background: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  .lang-menu {
    display: flex;
    min-heigth: 20px;
  }
`;
