import axios from "axios";
axios.defaults.withCredentials = true;

const baseURL = "/api/auth/resetPassword";

/**
 * Service function responsible for resetting a password.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} data reset password data
 * @returns response from the database
 */
export const resetPassword = async (data) => {
  try {
    const response = await axios.post(baseURL, data, { withCredentials: true });
    return response.data;
  } catch (e) {
    return e.message;
  }
};
