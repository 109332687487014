import axios from "axios";
axios.defaults.withCredentials = true;

const baseURL = "/api/auth/register";
/**
 * Service function responsible for registrating a new user.
 *
 * NOTE! See service_structure_comment.md for a detailed description of the structure of the function.
 * @param {*} data registration data
 * @returns response from the database
 */
export const register = async (data) => {
  try {
    const response = await axios.post(baseURL, data, { withCredentials: true });
    return response.data;
  } catch (e) {
    return e.message;
  }
};
